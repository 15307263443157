<template>
  <div class="body">
    <!-- banner区域 Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
    </div>
    <!-- banner区域 End -->

    <!-- switchBar区域 Start -->
    <div class="switchBar">
      <div class="switchBox">电力巡检覆盖传输</div>
    </div>
    <div class="line"></div>
    <!-- switchBar区域 End -->

    <!-- container区域 Start -->
    <div class="container">
      <div class="containerBox">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;变电站机器人巡检已经在国内逐渐推广及普及，
        LINKOM智能室外无线移动切换基站搭配天线已经配合多家国内电力巡检机器人进行了实地的项目应用及测试，
        优质的覆盖范围及快速切换机制保障了巡检机器人在实际应用中的传输稳定性。
        <img src="../../../../assets/mobile/img/solution/electricityPic.png" alt="" class="pic">
      </div>
    </div>
    <!-- container区域 End -->

    <!-- baseBar区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- baseBar区域 End -->
  </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
  name: 'Support',
  components: {
    mTopBar,
    mBaseBar,
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
  background-size: 335%;
}

/* switchBar区域 */
.switchBar {
  padding-top: 50px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #ffffff;
}

.switchBar .switchBox {
  margin: 0 auto;
  text-align: center;
  width: 800px;
  height: 200px;
  font-size: 72px;
  line-height: 200px;
  color: #ffffff;
  border-radius: 30px;
  background-color: #c3a35d;
}

.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
  padding: 50px;
  width: 100%;
  min-height: 500px;
}

.container .containerBox {
  padding: 100px;
  width: 100%;
  min-height: 300px;
  font-size: 70px;
  text-align: justify;
  background-color: #ffffff;
}

.container .containerBox .pic {
  margin-top: 150px;
  width: 1600px;
}
</style>